import React from "react"
import { Link } from "gatsby"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const InfoWeb = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "pt-BR",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | websites de blog / notícias"
        description="O website de blog ou notícias é ideal quando você tem conteúdos que você mesmo quer criar, atualizar ou deletar."
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="web design, web studio, serverless, user experience"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className="mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-10">
              <h1>websites de blog / notícias</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-4" />
            <div className="col-span-6">
              <span className="text-lg">
                O website de blog ou notícias é ideal quando você tem conteúdos
                que você mesmo quer criar, atualizar ou deletar.
                <br />
                <br />
                O website de blog ou notícias também tem todas as
                características do website informativo, assim você dispõe de
                algumas páginas personalizadas para publicar outros conteúdos.
                <br />
                <br />
                Neste caso nós prepararemos uma interface para você gerenciar
                seus conteúdos. Esses conteúdos são mantidos em uma base de
                dados e quando o website é reconstruído (uma operação
                corriqueira) o sistema acessa a base de dados e utiliza esta
                informação para reconstruir cada página.
                <br />
                <br />
                Veja um exemplo:
                <br />
                <br />
              </span>
              <div>
                <a
                  className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring px-3 py-1.5"
                  href="https://troncalradio.com/blogs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  troncalradio.com
                </a>
              </div>
              <div className="h-8" />
              <div className="text-center">
                <span className="text-2xl">
                  Vamos trabalhar juntos?
                  <br />
                  <br />
                </span>
                <Link
                  className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
                  to="/pt/lets-work-together/?service=blog-news-website"
                >
                  vamos fazer um website de blog / notícias
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default InfoWeb
